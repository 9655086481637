.about--page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 60vh;
  width: 80%;
}

.about--page h4 {
  margin: 5px 0;
}

#aboutSection:before { 
  content: ' '; 
  display: block; 
  position: relative; 
  width: 0; 
  height: 5em; 
  margin-top: -5em }

.about--page h2 {
  text-align: left;
}

.about--tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.about--tab {
  background-color: #361D2E;
  font-family: 'Paytone One', sans-serif;
  color: white;
  margin-right: 3px;
  padding: 8px 10px;
  cursor:pointer
}

li.about--active {
  background-color: white;
  color: #361D2E;
  border: 1px solid #361D2E;
  border-bottom: 0;
  transform: translateY(1px)
}

.about--menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.about--panel {
  display: none;
  background-color: white;
  border: 1px solid #361D2E;
  text-align: left;
  padding: 1% 3%;
}

.about--active {
  display: block;
}

.strong {
  font-weight: 600;
}

.about--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about--video {
  box-shadow: 8px 8px #361D2E;
  align-self: center;
  max-width: 100%;
}

.job-title--container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #361D2E;
  flex-wrap: wrap;
}

.job-title--container p {
  margin: 5px 0 0 0;
}

.job-title {
  font-weight: bold;
  font-size: 1.3em;
}

.job-company {
  margin: 5px 0;
}

.resume--button {
  color: white;
  text-decoration: none;
  background-color: #361D2E;
  border-radius: 0;
  border: none;
  padding: 8px 20px;
  font-family: 'Paytone One', sans-serif;
  margin: 10px auto;
  transition: .1s;
  cursor: pointer;
}

.resume--button a {
  color: inherit;
  text-decoration: none;
}

.resume--button:hover {
  transform: translate(-3px, -3px);
  box-shadow: 3px 3px #ed9835;
}

.experience--title {
  text-align: center;
}

.experience-button--container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 675px) {
  .about--page {
    width: 95%;
  }

  .job-title--container {
    border-bottom: none;
  }
}