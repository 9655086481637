.contact--section {
  width: 80%;
  min-height: 60vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.contact--section:before { 
  content: ''; 
  display: block; 
  position: relative; 
  width: 0; 
  height: 5em; 
  margin-top: -5em }

.contact--title {
  margin: 10% 0 1% 0;
}

.contact--list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.contact--icon {
  margin: 20px 20%;
  transition: .1s;
}

.contact--icon:hover {
  transform: translateY(-4px);
}

.contact--icon img {
  width: 64px;
}

@media only screen and (max-width: 675px) {
  .contact--list {
    width: 95%;
  }

  .contact--icon {
    width: 25%;
    margin: 3%;
  }
}