.socials {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  transform: .1s;
}

.socials-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials--icon {
  margin: 4px 0;
  transition: .1s;
}

.socials--icon:hover {
  transform: translateY(-2px);
  filter:hue-rotate(90)
}

.socials--line {
  width: 0;
  height: 90px;
  border-right: 2px solid #361D2E;
}

@media only screen and (max-width: 675px) {
  .socials {
    position: relative;
  }

  .socials-list {
    display: none;
  }
}
