.project--badges {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0px;
}

.project--badges li {
  border-radius: 12px;
  padding: 2px 7px;
  color: white;
  font-size: 12px;
  margin: 3px;
}

.badge--green {
  background-color: rgb(31, 140, 159);
}

.badge--blue {
  background-color: darkblue;
}

.badge--purple {
  background-color: darkmagenta;
}