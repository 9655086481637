.project-section {
  width: 80%;
  margin: auto;
}

.projects--container {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 675px) {
  .project-section {
    width: 95%;
  }
}