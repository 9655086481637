.intro {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 85%;
  margin: 0px 10% 0 10%;
  min-height: 100vh;
}

.intro--image {
  border: 2px solid #361D2E;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.intro--text {
  width: 55%;
  text-align: left;
  margin: 2%;
}

.intro--heading {
  font-size: 3.7em;
  margin: 0;
}

.image--container {
  position: relative;
  width: 40%;
  aspect-ratio: 1;
  max-width: 1700px;
  min-width: 150px;
  /* aspect-ratio: 1; */
  width: 30%;
  margin: 2%;
 }

.image--pattern {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 100%;
  height: 100%;
  z-index: 0;
 background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237051a2' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
 }

@media only screen and (max-width: 675px) {
  .intro {
    margin: 90px 3% 0 3%;
  }

  .intro--heading {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 450px) {
  .image--container {
    width: 80%;
  }

  .intro--text {
    width: 80%;
  }
}