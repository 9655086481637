.App {
  text-align: center;
  background-color: #ED9835;
  color: #361D2E;
  font-family: 'PT Sans';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1, h2, h3 {
  font-family: 'Paytone One';
  text-align: left;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

p, li {
  font-size: 1.25em;
}

button {
  font-size: 1em;
}