.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #ED9835;
  z-index: 10;
  transition: .1s;
}

.header--logo {
  font-family: 'Paytone One', sans-serif;
  font-size: 3em;
  margin: 10px 20px;
  cursor: pointer;
  transition: .1s;
}

.nav {
  margin: 10px 20px;
}

.nav--button {
  color: #361D2E;
  border-radius: 0;
  border: 1px solid #361D2E;
  padding: 8px 20px;
  font-family: 'Paytone One', sans-serif;
  margin: 10px 20px;
  transition: .1s;
  cursor: pointer;
}

.nav--button:hover {
  transform: translate(-3px, -3px);
  box-shadow: 3px 3px #361D2E;
}

.header__scrolled {
  background-color: #361D2E;
}

.header--logo__scrolled {
  color: #ED9835;
  font-size: 2em;
}

.nav--button__scrolled {
  margin: 5px 20px;
}

.nav--button__scrolled:hover {
  box-shadow: 3px 3px #ED9835;
}

.nav__scrolled {
  margin: 0px 20px;
}

@media only screen and (max-width: 675px) {
  .nav {
    width: 100%;
  }

  .nav--button {
    font-size: .8em;
    margin: 3px 5px;

  }

  .header--logo {
    display: none;
  }
}